import {GetStaticProps} from 'next';
import React from 'react';
import Homepage from '@/components/homepage';
import Layout from '@/components/layout';
import {ILocale} from '@/data/locales';
import {useLocaleState} from '@/store/locale';

type IProps = {
    locale: ILocale
}

const Page: React.FC<IProps> = ({locale}) => {
    const [{locale: locales}, {setLocale}] = useLocaleState();

    React.useEffect(() => {
        if (locales !== locale) {
            setLocale({locale});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    return (
        <Layout isHomepage>
            <Homepage />
        </Layout>
    );
};

export const getStaticProps: GetStaticProps = async context => {
    let locale;

    try {
        locale = (await import(`@/data/locales/${context.locale}`)).default;
    } catch (error) {
        return {notFound: true};
    }

    return ({props: {locale}});
};

export default Page;
