/* eslint-disable @next/next/no-img-element */
import {m} from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import pages from '@/data/pages';
import LocalesEnum from '@/enums/LocalesEnum';
import PagesEnum from '@/enums/PagesEnum';
import {useLocaleState} from '@/store/locale';
import {animations} from '@/utils/animations';
import styles from './homepage.module.css';

const Homepage: React.FC = () => {
    const router = useRouter();
    const lang = router.locale as LocalesEnum || LocalesEnum.cs;
    const [{locale}] = useLocaleState();

    return (
        <>
            <m.div
                initial="initial"
                animate="enter"
                variants={animations.homepage}
                className={styles.component}
                data-testid="component-homepage"
            >
                <Link href={pages[PagesEnum.products].routes[lang].route}>
                    <a
                        href={pages[PagesEnum.products].routes[lang].route}
                        className={styles.link}
                    >
                        <img
                            src="/icons/logo_truheat.svg"
                            alt=""
                            width={150}
                            height={40}
                            className={styles.logotyp}
                        />
                        <Image
                            priority
                            src="/homepage/01.jpg"
                            width={1000}
                            height={1000}
                            quality={100}
                            alt={locale.page.homepage.link1}
                            className={styles.image}
                        />

                        <h2 className={styles.heading}>
                            {locale.page.homepage.link1}
                        </h2>
                    </a>
                </Link>
                <Link href={pages[PagesEnum.purifier].routes[lang].route}>
                    <a
                        href={pages[PagesEnum.purifier].routes[lang].route}
                        className={styles.link}
                    >
                        <img
                            src="/icons/logo_aerconiq.svg"
                            alt=""
                            width={200}
                            height={40}
                            className={styles.logotyp}
                        />
                        <Image
                            priority
                            src="/homepage/02.jpg"
                            width={1000}
                            height={1000}
                            quality={100}
                            alt={locale.page.homepage.link2}
                            className={styles.image}
                        />

                        <h2 className={styles.heading}>
                            {locale.page.homepage.link2}
                        </h2>
                    </a>
                </Link>
                <Link href="https://www.zehnder-rekuperace.cz">
                    <a
                        href="https://www.zehnder-rekuperace.cz"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                    >
                        <span className={styles.linkHeading}>{locale?.page?.homepage?.link3heading}</span>
                        <Image
                            priority
                            src="/homepage/03.jpg"
                            width={1000}
                            height={1000}
                            quality={100}
                            alt={locale.page.homepage.link3}
                            className={styles.image}
                        />

                        <h2 className={styles.heading}>
                            {locale.page.homepage.link3}
                        </h2>
                    </a>
                </Link>
            </m.div>
            <h3 className={styles.subheading}>
                {locale.page.homepage.subheading}
            </h3>
            <div className={styles.socials}>
                <a
                    href={router.locale === LocalesEnum.pl
                        ? 'https://www.facebook.com/TomtonOffice/'
                        : 'https://www.facebook.com/tomtonradiators/'
                    }
                    className={styles.social}
                    target="_black"
                    rel="noreferrer noopener"
                    aria-label="facebook"
                >
                    <Image
                        src="/icons/facebook.svg"
                        alt=""
                        width={48}
                        height={48}
                    />
                </a>
                <a
                    href="https://www.instagram.com/tomton.multifunctionalheating/"
                    className={styles.social}
                    target="_black"
                    rel="noreferrer noopener"
                    aria-label="instagram"
                >
                    <Image
                        src="/icons/instagram.svg"
                        alt=""
                        width={48}
                        height={48}
                    />
                </a>
                <a
                    href="https://www.youtube.com/channel/UCPucxb4oNuEyDRIv5yHlGug"
                    className={styles.social}
                    target="_black"
                    rel="noreferrer noopener"
                    aria-label="you tube"
                >
                    <Image
                        src="/icons/youtube.svg"
                        alt=""
                        width={48}
                        height={48}
                    />
                </a>
                <a
                    href="https://www.linkedin.com/company/tomton/mycompany/?viewAsMember=true"
                    className={styles.social}
                    target="_black"
                    rel="noreferrer noopener"
                    aria-label="linkedin"
                >
                    <Image
                        src="/icons/linkedin.svg"
                        alt=""
                        width={48}
                        height={48}
                    />
                </a>
            </div>
            <p className={styles.copyright}>
                Copyright &copy; {new Date().getFullYear()} TOMTON s.r.o. All rights reserved.
            </p>
        </>
    );
};

export default Homepage;
